.button-dcs {
  font-family: 'Montserrat', Arial, sans-serif !important;
  font-weight: 600 !important;
  border: 2px solid rgb(0, 38, 100) !important;
  color: rgb(51,51,51) !important;
}
.primary-button-dcs {
  font-family: 'Montserrat', Arial, sans-serif !important;
  font-weight: 600 !important;
  background-color: rgb(0, 38, 100) !important;
  border: 2px solid rgb(0, 38, 100) !important;
}

.back-link-dcs {
  font-family: 'Montserrat', Arial, sans-serif !important;
  font-size: 14px !important;
}
@media only screen and (max-width: 768px) {
  body {
    padding: horizontal '5%';
  }
}
